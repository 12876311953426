/* @tailwind base;
@tailwind components;
@tailwind utilities; */

/* .input {
    margin: 10px;
} */

/* #timer {
    margin: 20px;
} */

#main {
    margin-top: 30px;
}

#display {
    margin: 20px;
    padding: 10px;
    font-family: 'PT Serif', serif;
    font-size: x-large;
    text-align: center;
}

#display .number {
    font-size: 100pt;
}

.bg-blue {
    background-color: #6cf;
}

.bg-teal {
    background-color: teal;
}

.btn {
    margin: 2px;
}